// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // API_URL: 'http://127.0.0.1:8000/',
  API_URL: 'https://testapi.postulateinfotech.in/',
  events_course_ctg: '12',
  internship_course_ctg: '14',

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: process.env['FRBS_KEY'] || '',
    authDomain: 'postulate-erp.firebaseapp.com',
    projectId: 'postulate-erp',
    storageBucket: 'postulate-erp.appspot.com',
    messagingSenderId: process.env['FRBS_PRJ_NO'] || '',
    appId: '1:116321280722:web:6fc0da3c41d1c7f7dd430b',
    measurementId: 'G-NKBRRYS2LX',
  },
  firebase_vapidKey:
    'BJfkgAhovUbjnTwDwKUH2NzTQdHhZc0BElPrctFdBwrar0NFvAkbuMVfJRGbgSkJpWSva0bZGVCg3StTRYx2uaU',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
